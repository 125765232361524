export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  // staging: true,
  apiUrl: 'https://staging.softrans.ro/api/v1',
  paymentUrl: 'https://sandboxsecure.mobilpay.ro',
  contentHash: "soyUshlcjtJZ8LQVqu4/ObCykgpFN2EUmfoESVaReiE=",
  afterLoginRedirect: '/home',
  status: {
    expired: -1,
    stalled: 0,
    draft: 1,
    open: 2,
    canceled: 3,
    confirmed: 4,
    done: 5,
    fail: 6
  },
  paymentStatus: {
    open: 'open',
    confirmed: 'confirmed',
    canceled: 'canceled',
    pending: 'pending',
    partialyRefunded: 'partialy_refunded',
    refunded: 'refunded',
    rejected: 'rejected'
  },

  priceListType: {
    distance: 1,
    seat: 2,
    cycle: 3,
    subscription5: 5,
    subscription14: 14,
    subscription30: 30
  },

  maxSeatsAllowed: 1,
};
