import { Routes } from '@angular/router';
import { authGuard } from './core/guard/auth.guard';
import { subscriptionResolver } from './screens/subscriptions/subscription/subscription.resolver';
import { subscriptionTicketsResolver } from './screens/subscriptions/subscription/subscription-tickets.resolver';

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./shared/layouts/base/base.component').then(
        (m) => m.BaseComponent
      ),
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./screens/home/home.component').then((m) => m.HomeComponent)
      },
      {
        path: 'buy-subscription',
        loadComponent: () =>
          import('./screens/buy-subscription/buy-subscription.component').then(
            (m) => m.BuySubscriptionComponent
          ),
      },
      {
        path: 'subscriptions/:id/buy-seat',
        loadComponent: () =>
          import('./screens/subscriptions/buy-seat/buy-seat.component').then(
            (m) => m.BuySeatComponent
          ),
      },
      {
        path: 'subscriptions',
        loadComponent: () =>
          import('./screens/subscriptions/subscriptions.component').then(
            (m) => m.SubscriptionsComponent
          ),
      },
      {
        path: 'subscriptions/:id',
        resolve: {
          'subscription': subscriptionResolver,
          'tickets': subscriptionTicketsResolver
        },
        loadComponent: () =>
          import('./screens/subscriptions/subscription/subscription.component').then(
            (m) => m.SubscriptionComponent
          ),
      },
      {
        path: 'profile',
        loadComponent: () =>
          import('./screens/profile/profile.component').then(
            (m) => m.ProfileComponent
          ),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },

  {
    path: '',
    loadComponent: () =>
      import('./shared/layouts/blank/blank.component').then(
        (m) => m.BlankComponent
      ),
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('./screens/login/login.component').then((m) => m.LoginComponent),
      },
      {
				path: 'forgot-password',
				loadComponent: () =>
					import('./screens/login/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
			},
			{
				path: 'pwreset/:code',
				loadComponent: () =>
					import('./screens/login/password-reset/password-reset.component').then((m) => m.PasswordResetComponent),
			},
			{
				path: 'register',
				loadComponent: () =>
					import('./screens/register/register.component').then((m) => m.RegisterComponent),
			},
      {
				path: 'verify-email',
				loadComponent: () =>
					import('./screens/register/verify-email/verify-email.component').then((m) => m.VerifyEmailComponent),
			},
      {
        path: 'not-found',
        loadComponent: () =>
          import('./screens/not-found/not-found.component').then(
            (m) => m.NotFoundComponent
          ),
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'not-found',
  },
];
