import { Injectable, inject } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Subscription } from '../model/subscription';
import { MpFormDataSubscription } from '../model/mp-form-data-subscription';
import { MpFormData } from '../model/mp-form-data';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private _http: HttpClient = inject(HttpClient);
  private _envService = inject(EnvironmentService);
  private _apiUrl: string = this._envService.getValue('apiUrl')

  async get(id: number): Promise<Subscription> {
    const subscription$ = this._http.get<Subscription>(
      `${this._apiUrl}/subscriptions/${id}`,
      { observe: 'body', responseType: 'json' }
    );
    return await firstValueFrom(subscription$);
  }

  async create(subscription: Partial<Subscription>): Promise<MpFormDataSubscription> {
    const data$ = this._http.post<MpFormDataSubscription>(
      `${this._apiUrl}/subscriptions/`,
      subscription
    )
    return await firstValueFrom(data$);
  }

  async getByClient(clientId: number): Promise<Subscription[]> {
    const subscriptions$ = this._http.get<Subscription[]>(
      `${this._apiUrl}/subscriptions/?client_id=${clientId}&sort(-id)`,
      { observe: 'body', responseType: 'json' }
    );
    return await firstValueFrom(subscriptions$);
  }

  getPaymentForm(subscriptionId: number): Observable<MpFormData> {
    return this._http.get<MpFormData>(
      `${this._apiUrl}/subscriptions/pf/${subscriptionId}`,
      { observe: 'body', responseType: 'json' }
    );
  }

  createAndSubmitPaymentForm(mpFormData: MpFormData) {
    let paymentForm = document.createElement('form');
    paymentForm.action = this._envService.getValue('paymentUrl');
    paymentForm.method = 'POST';

    let envKeyElm = document.createElement('input');
    envKeyElm.type = 'hidden';
    envKeyElm.name = 'env_key';
    envKeyElm.value = mpFormData.env_key;
    paymentForm.appendChild(envKeyElm);

    let dataElm = document.createElement('input');
    dataElm.type = 'hidden';
    dataElm.name = 'data';
    dataElm.value = mpFormData.data;
    paymentForm.appendChild(dataElm);

    let cipherElm = document.createElement('input');
    cipherElm.type = 'hidden';
    cipherElm.name = 'cipher';
    cipherElm.value = mpFormData.cipher;
    paymentForm.appendChild(cipherElm);

    let ivElm = document.createElement('input');
    ivElm.type = 'hidden';
    ivElm.name = 'iv';
    ivElm.value = mpFormData.iv;
    paymentForm.appendChild(ivElm);

    document.body.appendChild(paymentForm);
    paymentForm.submit();
  }

  getUsableTrainsByDate(subscriptionId: number, date: string): Observable<any> {
    return this._http.get<any>(
      `${this._apiUrl}/subscriptions/trains/${subscriptionId}/${date}`,
      { observe: 'body', responseType: 'json' }
    );
  }
}
