import { computed, effect, inject, Injectable, signal } from "@angular/core";
import { AuthUser } from "../model/auth-user";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { EnvironmentService } from "./environment.service";
import { LoginError } from "../model/login-error";
import { Client } from "../model/client";
import { User } from "../model/user";
import { RegisterResponse } from "../model/register-response";

const USER_STORAGE_KEY = '_cln';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  #userSignal = signal<AuthUser | null>(null);

  user = this.#userSignal.asReadonly();

  isLoggedIn = computed(() => !!this.user());

  private _http = inject(HttpClient);
  private _router = inject(Router);
  private _envService = inject(EnvironmentService);
  private _apiUrl: string = this._envService.getValue('apiUrl');

  constructor() {
    this.loadUserFromSession();
    effect(() => {
      const user = this.user();
      if (user) {
        sessionStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
      }
    });
  }

  loadUserFromSession() {
    const json = sessionStorage.getItem(USER_STORAGE_KEY);
    if (json) {
      const user = JSON.parse(json);
      this.#userSignal.set(user);
    }
  }

  async login(login: string, password: string): Promise<AuthUser | LoginError> {
    const login$ = this._http.post<AuthUser | LoginError>(
      `${this._apiUrl}/clin`, {
      login: login,
      password: password
    });
    const user = await firstValueFrom(login$);

    if ('is_error' in user) {
      throw user.content;
    }

    this.#userSignal.set(user);
    return user;
  }

  async logout() {
    sessionStorage.removeItem(USER_STORAGE_KEY);
    this.#userSignal.set(null);
    await this._router.navigateByUrl('/login');
  }

  async updateClient(client: Client): Promise<Client> {
    const client$ = this._http.put<Client>(
      `${this._apiUrl}/clients/${client.id}`,
      client
    );

    return await firstValueFrom(client$);
  }

  async createUser(user: User): Promise<RegisterResponse> {
    const response$ = this._http.post<RegisterResponse>(
      `${this._apiUrl}/register`,
      user
    );

    const response = await firstValueFrom(response$);

    if (response.is_error) {
      throw response.content;
    }

    return response;
  }

  async forgotPassword(email: string): Promise<string> {
    const message$ = this._http.post<string>(
      `${this._apiUrl}/pwreset`,
      {
        email: email,
        source: 'subscriptions'
      }
    );
    return await firstValueFrom(message$);
  }

  async resetPassword(code: string, password: string): Promise<string> {
    const message$ = this._http.post<string>(
      `${this._apiUrl}/pwreset`,
      {
        validation_code: code,
        password: password
      }
    );
    return await firstValueFrom(message$);
  }

  async verifyEmail(sid: string, cid: string, code: string): Promise<string> {
    const message$ = this._http.post<string>(
      `${this._apiUrl}/validate`,
      {
        s: sid,
        n: cid,
        c: code,
      }
    );

    return await firstValueFrom(message$);
  }

}