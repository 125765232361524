import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const errorCatchingInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);

  return next(req).pipe(
    catchError(err => {
      console.error("errorCatchingInterceptor", err);
      if (err.status === 428) {
        alert("Sesiunea a expirat, veti fi redirectionat catre pagina de login.");
        auth.logout();
      }
      return throwError(() => err);
    })
  );
};
