import { inject, Injectable } from '@angular/core';
import { Subscription } from '../model/subscription';
import { AuthUser } from '../model/auth-user';
import { DatePipe } from '@angular/common';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})

export class UtilsService {

  private _datePipe: DatePipe = inject(DatePipe);
  private _envService = inject(EnvironmentService);

  // we create this because dateToIsoString is returning the UTC date time
  // which is not what we want, we want the local date time
  dateToYmd(date: Date) {
    return date.getFullYear() + '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
      date.getDate().toString().padStart(2, '0');
  }

  statusAsText(status: number) {
    const statusList = this._envService.getValue('status');
    switch (Number(status)) {
      case statusList.expired:
        return 'Expirirat';
      case statusList.open:
        return 'Nefinalizat';
      case statusList.canceled:
        return 'Anulat';
      case statusList.confirmed:
        return 'Confirmat';
      case statusList.done:
        return 'Finalizat';
      default:
        return 'Nedefinit';
    }
  }

  isExpired(subscription: Subscription) {
    return new Date(subscription.valid_to) < new Date();
  }

  subscriptionTitle(subscription: Subscription) {
    return `Abonament Nr. ${subscription.id} ${subscription.valid_for == 30 ? '(lunar)' : `(${subscription.valid_for} zile)`}`;
  }

  canShowSubscriptionPaymentButton(subscription: Subscription) {
    return subscription.status == this._envService.getValue('status').open &&
      subscription.payment_status == null &&
      subscription.price > 0 &&
      !this.isExpired(subscription);
  }

  canShowSubscriptionBuySeatButton(subscription: Subscription) {
    return subscription.status == this._envService.getValue('status').done &&
      !this.isExpired(subscription);
  }

  stringToTime(time: string | null) {
    if (time == null) {
      return new Date(0, 0, 0, 0, 0, 0);
    }
    const parts = time.split(':');
    return new Date(0, 0, 0, Number(parts[0]), Number(parts[1]), 0);
  }

  computeTripMask(originPos: number, destinationPos: number) {
    if (originPos > destinationPos) {
      return 0;
    }

    let mask = 0;
    for (let i = originPos; i < destinationPos; i++) {
      mask |= Math.pow(2, i - 1);
    }

    return mask;
  }

  computeVat(user: AuthUser | null , price: number) {
    const vatRate = Number(user?.session?.vatRate || 0);
    return (vatRate * price) / (100 + vatRate);
  }

  toDbDate(date: Date | null) {
    return this._datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  toDbDateTime(date: Date | null) {
    return this._datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss') || '';
  }
}
